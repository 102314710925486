.exyConItem {
  width: 100%;
  padding: 12px;
  display: flex;
}

.offeringImage {
  border-radius: 6px;
  object-fit: cover;
}

.offeringTitle {
  font-weight: 500;
  font-size: 24px;
}

.moreMenuOpenButton {
  background: #dcd9f2 !important;
  margin: 0 !important;
}

.moreMenu {
  background-color: white;
  color: #272522;
  white-space: nowrap;
  border-radius: 5px 0px 5px 5px;
  overflow: hidden;
}

.moreMenuItem {
  padding: 0 8px;
}

.moreMenuItem:hover {
  background-color: #493ab1;
  color: white;
  cursor: pointer;
}

@media screen and (max-width: 780px) {
  .exyConItem {
    margin-bottom: 18px;
  }

  .offeringImage {
    width: 111px;
    height: 130px;
    margin-right: 10px;
  }

  .offeringTitle {
    font-size: 16px;
  }

  .offeringTimimg {
    font-size: 14px;
  }

  .launchMeeting {
    margin-right: 10px !important;
    height: unset !important;
    font-size: 14px !important;
    padding: 9px 13px !important;
    white-space: nowrap;
  }

  .moreMenuOpenButton {
    min-width: 45px !important;
    width: 45px !important;
    height: unset !important;
    padding: 5px 0 !important;
  }
}

@media screen and (min-width: 781px) {
  .exyConItem {
    margin-bottom: 24px;
  }

  .offeringImage {
    width: 150px;
    height: 176px;
    margin-right: 16px;
  }

  .offeringTitle {
    font-size: 24px;
  }

  .offeringTimimg {
    font-size: 20px;
  }

  .launchMeeting {
    padding-left: 59px !important;
    padding-right: 59px !important;
    margin-right: 16px !important;
  }

  .moreMenuOpenButton {
    min-width: 64px !important;
    width: 64px !important;
    padding: 0 !important;
  }
}

.zoomLink {
  word-break: break-all;
}
