.modal_root {
  padding: 24px !important;
}

.types {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.type_container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.type {
  display: flex;
  gap: 16px;
  border: 1px solid #ededed;
  border-radius: 6px;
  padding: 16px;
  cursor: pointer;
  width: 100%;
  user-select: none;
}

.type:hover {
  border-color: #493ab1;
}

.type_selected {
  border-color: #493ab1;
}

.type_label {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 6px;
}

.type_description {
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.02em;
  color: #27252299;
}
